import MuiSvgIcon from "@material-ui/core/SvgIcon";
import React from "react";
import { IconProps } from "../icons";

interface OwnProps {
  title?: string;
}

type AllProps = IconProps & OwnProps;

const LockIcon: React.FunctionComponent<AllProps> = ({
  nativeColor,
  ...props
}) => (
  <MuiSvgIcon viewBox="0 0 16 16" {...props}>
    <svg width={16} height={16} viewBox="0 0 16 16" {...props}>
      <title>{props.title}</title>
      <g transform="translate(-1031 -360)" fill="none" fillRule="evenodd">
        <rect fill="#FFF" x={331} y={104} width={837} height={538} rx={4} />
        <rect
          transform="rotate(-180 518 205.5)"
          width={110}
          height={40}
          rx={10}
          fill="#F7F7F7"
        />
        <rect
          stroke="#FFF"
          strokeWidth={2}
          transform="rotate(-180 981 391)"
          x={921}
          y={366}
          width={120}
          height={50}
          rx={14}
        />
        <path
          d="M1042.143 367.167h-.429v-1.34c0-1.558-1.218-2.827-2.714-2.827s-2.714 1.269-2.714 2.827v1.34h-.429c-.473 0-.857.4-.857.893v3.571c0 .493.384.893.857.893h6.286c.473 0 .857-.4.857-.893v-3.571c0-.493-.384-.893-.857-.893zm-1.857 0h-2.572v-1.34c0-.738.577-1.339 1.286-1.339.709 0 1.286.6 1.286 1.34v1.339z"
          fill="#9b9b9b"
          fillRule="nonzero"
        />
      </g>
    </svg>
  </MuiSvgIcon>
);

export default LockIcon;
