import { get, has, isArray, isObject } from "lodash";
import queryString from "query-string";
import { AnyAction } from "redux";
import { Action } from "redux-actions";
import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";

import { ApplicationState } from "../";
import callApi from "../../utils/callApi";
import { errorHandler } from "../../utils/errorHandler";
import { enqueueSnackbar } from "../notifications";
import {
  activateSubscription,
  calculateProRata,
  changeSubscription,
  checkout,
  endSubscription,
  extendCampaign,
  fetchFlowPlans,
  getActiveCampaignSubscription,
  getActiveOrganisationSubscription,
  getAdViews,
  getAllUserCampaignSubscriptions,
  getSubscriptionById,
  getSubscriptionByPurchaseId,
  getUserSubscriptionByCampaignId,
  postToPage,
  patchSubscription,
  setFlowPlansBillingFrequency,
  setProRata,
  setSelectedFlowPlan,
  createSubscription,
  masterCampaignCheckout,
  resetCampaignSubscriptionState,
} from "./routines";
import {
  CheckoutPayload,
  CheckoutType,
  OrganisationCheckoutPayload,
  CalculateProRataPayload, SubscriptionState,
  PagePostingPayload,
  FetchFlowPlansPayload,
  CreateSubscriptionPayload,
  MasterCampaignCheckoutPayload,
} from "./types";
import { Modes } from '../navigation';
import { Organisation } from '../organisation';

function* getCurrentUser(): any {
  const { auth } = yield select((state: ApplicationState) => state);
  const roles = get(auth, "user.roles", []);
  const role = roles.includes("flow-admin") ? "admin/" : "";
  const landlordUser = get(auth, "user._id", undefined);
  return { role, landlordUser };
}

function* handleGetAdViews(action: AnyAction): any {
  try {
    const parameters = action.payload;
    const res = yield call(
      callApi,
      "get",
      `/locales/cost-per-impressions?${queryString.stringify(parameters)}`
    );
    yield put(
      getAdViews.success({
        adViews: res.data,
      })
    );
  } catch (err) {
    if ((err as any).response) {
      yield put(
        enqueueSnackbar({
          message: errorHandler((err as any).response),

          options: { variant: "error" },
        })
      );

      yield put(getAdViews.failure(errorHandler((err as any).response)));
    } else {
      yield put(getAdViews.failure("An unknown error occured"));
    }
  } finally {
    yield put(getAdViews.fulfill());
  }
}

function* handleFetchFlowPlans(action: Action<FetchFlowPlansPayload>): any {
  try {
    const { role } = yield call(getCurrentUser);

    const selectedOrganisation: Organisation | undefined = yield select(
      ({ organisation }: ApplicationState) => (organisation.subOrganisation || organisation.organisation)
    );
    const selectedOrganisationId = get(selectedOrganisation, "_id");

    const params = action.payload;
    const res = yield call(
      callApi,
      "get",
      `/${role}flow-plans?${queryString.stringify({ ...params, organisationId: selectedOrganisationId })}`
    );
    yield put(
      fetchFlowPlans.success({
        flowPlans: get(res, 'data.plans', []),
        count: get(res, 'data.count', 0)
      })
    );
  } catch (err) {
    if ((err as any).response) {
      yield put(
        enqueueSnackbar({
          message: errorHandler((err as any).response),
          options: { variant: "error" },
        })
      );
      yield put(fetchFlowPlans.failure(errorHandler((err as any).response)));
    } else {
      yield put(fetchFlowPlans.failure("An unknown error occured"));
    }
  } finally {
    yield put(fetchFlowPlans.fulfill());
  }
}

function* handleSetSelectedFlowPlan(action: AnyAction): any {
  try {
    yield put(setSelectedFlowPlan.request());
    yield put(setSelectedFlowPlan.success(action.payload));
  } catch (err) {
    console.error(err);
    if (err) {
      yield put(setSelectedFlowPlan.failure(errorHandler(err)));
    }
  } finally {
    yield put(setSelectedFlowPlan.fulfill());
  }
}

function* handleCheckout(action: Action<CheckoutPayload>): any {
  const checkoutPayload = action.payload;
  try {
    const payload = checkoutPayload.data;

    const isLinkOut = yield select(({ navigation }: ApplicationState) =>
      navigation.mode === Modes.LinkOut
    );

    const selectedOrganisation: Organisation | undefined = yield select(
      ({ organisation }: ApplicationState) => (organisation.subOrganisation || organisation.organisation)
    );
    const selectedOrganisationId = get(selectedOrganisation, "_id");

    let endpoint = "";
    if (checkoutPayload.type === CheckoutType.Campaign)
      endpoint = `/campaign/checkout?isLinkOut=${isLinkOut}&organisationId=${selectedOrganisationId}`;
    else if (checkoutPayload.type === CheckoutType.Organisation) {
      const orgId = (payload as OrganisationCheckoutPayload).organisationId;
      endpoint = `/v3/organisations/${orgId}/checkout?isLinkOut=${isLinkOut}`;
    }

    const res = yield call(callApi, "post", endpoint, {
      data: payload,
    });
    yield put(
      checkout.success({
        checkout: res.data,
      })
    );
  } catch (err) {
    if ((err as any).response) {
      const errorMessage = errorHandler((err as any).response);
      yield put(checkout.failure(errorMessage));
    }
  } finally {
    yield put(checkout.fulfill());
  }
}
function* handlePostToPage(action: Action<PagePostingPayload>): any {
  const selectedOrganisation: Organisation | undefined = yield select(
    ({ organisation }: ApplicationState) => (organisation.subOrganisation || organisation.organisation)
  );
  const selectedOrganisationId = get(selectedOrganisation, "_id");

  const postPayload = action.payload;
  try {
    const res = yield call(callApi, "post", `/facebook/post-to-facebook-page?organisationId=${selectedOrganisationId}`, {
      data: postPayload,
    });
    yield put(postToPage.success(res.data));
  } catch (err) {
    if ((err as any).response) {
      const errorMessage = errorHandler((err as any).response);
      yield put(postToPage.failure(errorMessage));
    }
  } finally {
    yield put(postToPage.fulfill());
  }
}

function* handleEndSubscription(action: AnyAction): any {
  try {
    const { role } = yield call(getCurrentUser);

    const selectedOrganisation: Organisation | undefined = yield select(
      ({ organisation }: ApplicationState) => (organisation.subOrganisation || organisation.organisation)
    );
    const selectedOrganisationId = get(selectedOrganisation, "_id");

    const params = get(action, "payload.id");
    const res = yield call(
      callApi,
      "put",
      `/${role}subscriptions/${params}/cancel?organisationId=${selectedOrganisationId}`
    );
    yield put(
      endSubscription.success({
        endedSubscription: res.data,
      })
    );
  } catch (err) {
    if ((err as any).response) {
      yield put(
        enqueueSnackbar({
          message: errorHandler((err as any).response),
          options: { variant: "error" },
        })
      );
      yield put(endSubscription.failure(errorHandler((err as any).response)));
    } else {
      yield put(endSubscription.failure("An unknown error occured"));
    }
  } finally {
    yield put(endSubscription.fulfill());
  }
}

function* handleActivateSubscription(action: AnyAction): any {
  try {
    const { role } = yield call(getCurrentUser);

    const selectedOrganisation: Organisation | undefined = yield select(
      ({ organisation }: ApplicationState) => (organisation.subOrganisation || organisation.organisation)
    );
    const selectedOrganisationId = get(selectedOrganisation, "_id");

    const subscriptionId = get(action, "payload.subscriptionId");
    const response = yield call(
      callApi,
      "put",
      `/${role}subscriptions/${subscriptionId}/activate?organisationId=${selectedOrganisationId}`
    );
    yield put(
      activateSubscription.success({
        activatedSubscription: response.data,
        checkout_url: get(response, "data.checkout_url"),
      })
    );
  } catch (error) {
    if ((error as any).response) {
      yield put(
        enqueueSnackbar({
          message: errorHandler((error as any).response),
          options: { variant: "error" },
        })
      );
      yield put(
        activateSubscription.failure(errorHandler((error as any).response))
      );
    } else {
      yield put(activateSubscription.failure("An unknown error occurred"));
    }
  } finally {
    yield put(activateSubscription.fulfill());
  }
}

function* handleGetActiveCampaignSubscription(action: AnyAction): any {
  try {
    const { role } = yield call(getCurrentUser);

    const selectedOrganisation: Organisation | undefined = yield select(
      ({ organisation }: ApplicationState) => (organisation.subOrganisation || organisation.organisation)
    );
    const selectedOrganisationId = get(selectedOrganisation, "_id");

    const params = action.payload;
    const res = yield call(
      callApi,
      "get",
      `/${role}flow-plans?${queryString.stringify({ ...params, organisationId: selectedOrganisationId })}`
    );
    yield put(
      getActiveCampaignSubscription.success({
        activeCampaign: res.data,
      })
    );
  } catch (err) {
    if ((err as any).response) {
      yield put(
        enqueueSnackbar({
          message: errorHandler((err as any).response),
          options: { variant: "error" },
        })
      );
      yield put(
        getActiveCampaignSubscription.failure(
          errorHandler((err as any).response)
        )
      );
    } else {
      yield put(
        getActiveCampaignSubscription.failure("An unknown error occured")
      );
    }
  } finally {
    yield put(getActiveCampaignSubscription.fulfill());
  }
}

function* handleGetAllUserSubscriptions(action: AnyAction): any {
  try {
    const { role } = yield call(getCurrentUser);

    const selectedOrganisation: Organisation | undefined = yield select(
      ({ organisation }: ApplicationState) => (organisation.subOrganisation || organisation.organisation)
    );
    const selectedOrganisationId = get(selectedOrganisation, "_id");

    const userId = action.payload;
    const res = yield call(
      callApi,
      "get",
      `/${role}/users/${userId}/subscriptions?organisationId=${selectedOrganisationId}`
    );
    yield put(
      getAllUserCampaignSubscriptions.success({
        userCampaignSubscriptions: res.data,
      })
    );
  } catch (err) {
    if ((err as any).response) {
      yield put(
        enqueueSnackbar({
          message: errorHandler((err as any).response),
          options: { variant: "error" },
        })
      );
      yield put(
        getAllUserCampaignSubscriptions.failure(
          errorHandler((err as any).response)
        )
      );
    } else {
      yield put(
        getAllUserCampaignSubscriptions.failure("An unknown error occured")
      );
    }
  } finally {
    yield put(getAllUserCampaignSubscriptions.fulfill());
  }
}

function* handleGetActiveOrganisationSubscription(action: AnyAction): any {
  try {
    const { role } = yield call(getCurrentUser);
    const organisation_id = action.payload;
    const res = yield call(
      callApi,
      "get",
      `/v3/${role}/organisations/${organisation_id}/subscription`
    );
    yield put(
      getActiveOrganisationSubscription.success({
        activeOrganisationSubscription: res.data,
      })
    );
  } catch (err) {
    if ((err as any).response) {
      yield put(
        enqueueSnackbar({
          message: errorHandler((err as any).response),
          options: { variant: "error" },
        })
      );
      yield put(
        getActiveOrganisationSubscription.failure(
          errorHandler((err as any).response)
        )
      );
    } else {
      yield put(
        getActiveOrganisationSubscription.failure("An unknown error occured")
      );
    }
  } finally {
    yield put(getActiveOrganisationSubscription.fulfill());
  }
}

function* handleGetUserSubscriptionByCampaignId(action: AnyAction): any {
  try {
    yield put(getUserSubscriptionByCampaignId.request());
    const { role } = yield call(getCurrentUser);

    const selectedOrganisation: Organisation | undefined = yield select(
      ({ organisation }: ApplicationState) => (organisation.subOrganisation || organisation.organisation)
    );
    const selectedOrganisationId = get(selectedOrganisation, "_id");

    const query = get(action, "payload");
    const response = yield call(
      callApi,
      "get",
      `/${role}subscriptions/?${queryString.stringify({ query, organisationId: selectedOrganisationId })}`
    );

    yield put(getUserSubscriptionByCampaignId.success(response.data));
  } catch (err) {
    if ((err as any).response) {
      yield put(
        enqueueSnackbar({
          message: errorHandler((err as any).response),
          options: { variant: "error" },
        })
      );
    }
  } finally {
    yield put(getUserSubscriptionByCampaignId.fulfill());
  }
}

function* handleExtendCampaign(action: AnyAction): any {
  const selectedOrganisation: Organisation | undefined = yield select(
    ({ organisation }: ApplicationState) => (organisation.subOrganisation || organisation.organisation)
  );
  const selectedOrganisationId = get(selectedOrganisation, "_id");

  const extendCampaignPayload = action.payload;
  try {
    const res = yield call(callApi, "post", `/campaign/extend-checkout?organisationId=${selectedOrganisationId}`, {
      data: extendCampaignPayload,
    });
    yield put(
      extendCampaign.success({
        extendCampaign: res.data,
      })
    );
  } catch (err) {
    if ((err as any).response) {
      let errorMessage = errorHandler((err as any).response);
      if (isObject(errorMessage) && isArray(get(errorMessage, 'errors')) && has(errorMessage, 'errors.0.msg')) {
        const errors = get(errorMessage, 'errors', []);
        errorMessage = errors.reduce((accMsg: string, error: any) => {
          return `${accMsg}${get(error, 'msg')}.\n `;
        }, '');
      }
      yield put(extendCampaign.failure(errorMessage));
    }
  } finally {
    yield put(extendCampaign.fulfill());
  }
}

function* handleChangeSubscription(action: AnyAction): any {
  try {
    const selectedOrganisation: Organisation | undefined = yield select(
      ({ organisation }: ApplicationState) => (organisation.subOrganisation || organisation.organisation)
    );
    const selectedOrganisationId = get(selectedOrganisation, "_id");

    const res = yield call(callApi, "post", `/campaign/change-subscription?organisationId=${selectedOrganisationId}`, {
      data: action.payload,
    });
    yield put(changeSubscription.success(res.data));
  } catch (err) {
    if ((err as any).response) {
      yield put(
        enqueueSnackbar({
          message: errorHandler((err as any).response),
          options: { variant: "error" },
        })
      );
      yield put(
        changeSubscription.failure(errorHandler((err as any).response))
      );
    } else {
      yield put(changeSubscription.failure("An unknown error occured"));
    }
  } finally {
    yield put(changeSubscription.fulfill());
  }
}

function* handleSetFlowPlansBillingFrequency(action: AnyAction): any {
  try {
    yield put(setFlowPlansBillingFrequency.request());
    yield put(setFlowPlansBillingFrequency.success(action.payload));
  } catch (err) {
    console.error(err);
    if (err) {
      yield put(setFlowPlansBillingFrequency.failure(errorHandler(err)));
    }
  } finally {
    yield put(setFlowPlansBillingFrequency.fulfill());
  }
}

function* handleCalculateProRata(action: Action<CalculateProRataPayload>): any {
  try {
    const selectedOrganisation: Organisation | undefined = yield select(
      ({ organisation }: ApplicationState) => (organisation.subOrganisation || organisation.organisation)
    );
    const selectedOrganisationId = get(selectedOrganisation, "_id");

    yield put(calculateProRata.request());

    const subscriptionId = action.payload.subscriptionId;
    const flowPlanId = action.payload.flowPlanId;
    const billingFrequency = action.payload.billingFrequency;

    const response = yield call(
      callApi,
      "post",
      `/subscriptions/${subscriptionId}/calculate-pro-rata?organisationId=${selectedOrganisationId}`,
      {
        data: { flowPlanId, billingFrequency },
      }
    );

    yield put(calculateProRata.success(response.data));
  } catch (err) {
    console.error(err);
    if (err) {
      yield put(calculateProRata.failure(errorHandler(err)));
    }
  } finally {
    yield put(calculateProRata.fulfill());
  }
}

function* handleSetProRata(action: Action<number>): any {
  try {
    yield put(setProRata.request());
    yield put(setProRata.success(action.payload));
  } catch (err) {
    console.error(err);
    if (err) {
      yield put(setProRata.failure(errorHandler(err)));
    }
  } finally {
    yield put(setProRata.fulfill());
  }
}

function* handleGetSubscriptionByPurchaseId(action: Action<string>): any {
  try {
    const selectedOrganisation: Organisation | undefined = yield select(
      ({ organisation }: ApplicationState) => (organisation.subOrganisation || organisation.organisation)
    );
    const selectedOrganisationId = get(selectedOrganisation, "_id");

    yield put(getSubscriptionByPurchaseId.request());

    const purchaseId = action.payload;

    const response = yield call(
      callApi,
      "get",
      `/subscriptions?${queryString.stringify({ purchaseId, organisationId: selectedOrganisationId })}`
    );

    yield put(getSubscriptionByPurchaseId.success(get(response, "data[0]")));
  } catch (err) {
    console.error(err);
    if (err) {
      yield put(getSubscriptionByPurchaseId.failure(errorHandler(err)));
    }
  } finally {
    yield put(getSubscriptionByPurchaseId.fulfill());
  }
}

function* handleGetSubscriptionById(action: Action<string>): any {
  try {
    yield put(getSubscriptionById.request());

    const subscriptionId = action.payload;

    const { role } = yield call(getCurrentUser);

    const selectedOrganisation: Organisation | undefined = yield select(
      ({ organisation }: ApplicationState) => (organisation.subOrganisation || organisation.organisation)
    );
    const selectedOrganisationId = get(selectedOrganisation, "_id");

    const response = yield call(
      callApi,
      "get",
      `/${role}subscriptions/${subscriptionId}?organisationId=${selectedOrganisationId}`
    );

    yield put(getSubscriptionById.success(response.data));
  } catch (err) {
    console.error(err);
    if (err) {
      yield put(getSubscriptionById.failure(errorHandler(err)));
    }
  } finally {
    yield put(getSubscriptionById.fulfill());
  }
}

function* handlePatchSubscription(
  action: Action<Partial<SubscriptionState>>
): any {
  try {
    yield put(patchSubscription.success(action.payload));
  } catch (err) {
    if (err) {
      console.error(err);
      yield put(patchSubscription.failure(errorHandler(err)));
    }
  } finally {
    yield put(patchSubscription.fulfill());
  }
}

function* handleCreateSubscription(
  action: Action<CreateSubscriptionPayload>
): any {
  try {
    yield put(createSubscription.request());

    const { role } = yield call(getCurrentUser);

    const selectedOrganisation: Organisation | undefined = yield select(
      ({ organisation }: ApplicationState) => (organisation.subOrganisation || organisation.organisation)
    );
    const selectedOrganisationId = get(selectedOrganisation, "_id");

    const response = yield call(
      callApi,
      "post",
      `/${role}subscriptions/?organisationId=${selectedOrganisationId}`,
      {
        data: action.payload,
      }
    );

    yield put(createSubscription.success(response.data));
  } catch (err) {
    if (err) {
      console.error(err);
      yield put(createSubscription.failure(errorHandler(err)));
    }
  } finally {
    yield put(createSubscription.fulfill());
  }
}

function* handleMasterCampaignCheckout(
  action: Action<MasterCampaignCheckoutPayload>
): any {
  try {
    yield put(masterCampaignCheckout.request());

    const { role } = yield call(getCurrentUser);

    const selectedOrganisation: Organisation | undefined = yield select(
      ({ organisation }: ApplicationState) => (organisation.subOrganisation || organisation.organisation)
    );
    const selectedOrganisationId = get(selectedOrganisation, "_id");

    const isLinkOut = yield select(({ navigation }: ApplicationState) =>
      navigation.mode === Modes.LinkOut
    );

    const response = yield call(
      callApi,
      "post",
      `/${role}subscriptions/checkout?isLinkOut=${isLinkOut}&organisationId=${selectedOrganisationId}`,
      {
        data: action.payload,
      }
    );

    yield put(masterCampaignCheckout.success(response.data));
  } catch (err) {
    if (err) {
      console.error(err);
      yield put(masterCampaignCheckout.failure(errorHandler(err)));
    }
  } finally {
    yield put(masterCampaignCheckout.fulfill());
  }
}

function* handleResetCampaignSubscriptionState(
  action: Action<MasterCampaignCheckoutPayload>
): any {
  try {
    yield put(resetCampaignSubscriptionState.success(action.payload));
  } catch (err) {
    if (err) {
      console.error(err);
      yield put(resetCampaignSubscriptionState.failure(errorHandler(err)));
    }
  } finally {
    yield put(resetCampaignSubscriptionState.fulfill());
  }
}

function* handleGetAdViewsWatcher(): any {
  yield takeEvery(getAdViews.TRIGGER, handleGetAdViews);
}

function* handleFetchFlowPlansWatcher(): any {
  yield takeEvery(fetchFlowPlans.TRIGGER, handleFetchFlowPlans);
}

function* handleSetSelectedFlowPlanWatcher(): any {
  yield takeEvery(setSelectedFlowPlan.TRIGGER, handleSetSelectedFlowPlan);
}

function* handleCheckoutWatcher(): any {
  yield takeEvery(checkout.TRIGGER, handleCheckout);
}
function* handlePostToPageWatcher(): any {
  yield takeEvery(postToPage.TRIGGER, handlePostToPage);
}

function* handleEndSubscriptionWatcher(): any {
  yield takeEvery(endSubscription.TRIGGER, handleEndSubscription);
}

function* handleActivateSubscriptionWatcher(): any {
  yield takeEvery(activateSubscription.TRIGGER, handleActivateSubscription);
}

function* handleGetActiveCampaignSubscriptionWatcher(): any {
  yield takeEvery(
    getActiveCampaignSubscription.TRIGGER,
    handleGetActiveCampaignSubscription
  );
}

function* handleGetAllUserSubscriptionsWatcher(): any {
  yield takeEvery(
    getAllUserCampaignSubscriptions.TRIGGER,
    handleGetAllUserSubscriptions
  );
}

function* handleGetActiveOrganisationSubscriptionWatcher(): any {
  yield takeEvery(
    getActiveOrganisationSubscription.TRIGGER,
    handleGetActiveOrganisationSubscription
  );
}

//handleGetUserSubscriptionByCampaignId

function* handleGetUserSubscriptionByCampaignIdWatcher(): any {
  yield takeEvery(
    getUserSubscriptionByCampaignId.TRIGGER,
    handleGetUserSubscriptionByCampaignId
  );
}

function* handleExtendCampaignWatcher(): any {
  yield takeEvery(extendCampaign.TRIGGER, handleExtendCampaign);
}

function* handleChangeSubscriptionWatcher(): any {
  yield takeEvery(changeSubscription.TRIGGER, handleChangeSubscription);
}

function* handleSetFlowPlansBillingFrequencyWatcher(): any {
  yield takeEvery(
    setFlowPlansBillingFrequency.TRIGGER,
    handleSetFlowPlansBillingFrequency
  );
}

function* handleCalculateProRataWatcher(): any {
  yield takeEvery(calculateProRata.TRIGGER, handleCalculateProRata);
}

function* handleSetProRataWatcher(): any {
  yield takeEvery(setProRata.TRIGGER, handleSetProRata);
}

function* handleGetSubscriptionByPurchaseIdWatcher(): any {
  yield takeEvery(
    getSubscriptionByPurchaseId.TRIGGER,
    handleGetSubscriptionByPurchaseId
  );
}

function* handleGetSubscriptionByIdWatcher(): any {
  yield takeEvery(getSubscriptionById.TRIGGER, handleGetSubscriptionById);
}

function* patchSubscriptionWatcher(): any {
  yield takeEvery(patchSubscription.TRIGGER, handlePatchSubscription);
}

function* createSubscriptionWatcher(): any {
  yield takeEvery(createSubscription.TRIGGER, handleCreateSubscription);
}

function* masterCampaignCheckoutWatcher(): any {
  yield takeEvery(masterCampaignCheckout.TRIGGER, handleMasterCampaignCheckout);
}

function* resetCampaignSubscriptionStateWatcher(): any {
  yield takeEvery(resetCampaignSubscriptionState.TRIGGER, handleResetCampaignSubscriptionState);
}

export function* subscriptionsSaga(): any {
  yield all([
    fork(handleFetchFlowPlansWatcher),
    fork(handleSetSelectedFlowPlanWatcher),
    fork(handleGetAdViewsWatcher),
    fork(handleCheckoutWatcher),
    fork(handlePostToPageWatcher),
    fork(handleEndSubscriptionWatcher),
    fork(handleActivateSubscriptionWatcher),
    fork(handleGetActiveCampaignSubscriptionWatcher),
    fork(handleGetAllUserSubscriptionsWatcher),
    fork(handleGetActiveOrganisationSubscriptionWatcher),
    fork(handleGetUserSubscriptionByCampaignIdWatcher),
    fork(handleExtendCampaignWatcher),
    fork(handleChangeSubscriptionWatcher),
    fork(handleSetFlowPlansBillingFrequencyWatcher),
    fork(handleCalculateProRataWatcher),
    fork(handleSetProRataWatcher),
    fork(handleGetSubscriptionByPurchaseIdWatcher),
    fork(handleGetSubscriptionByIdWatcher),
    fork(patchSubscriptionWatcher),
    fork(createSubscriptionWatcher),
    fork(masterCampaignCheckoutWatcher),
    fork(resetCampaignSubscriptionStateWatcher),
  ]);
}
