import { createBrowserHistory } from "history";
import * as React from "react";
import * as ReactDOM from "react-dom";
import configureStore from "./configureStore";
import Main from "./main";
import registerServiceWorker from "./registerServiceWorker";
import GlobalStyles from "./styles";
import { initializeGTM } from "./utils/GoogleTagManager";

// Dynamically load Google Maps script
const loadGoogleMapsScript = () => {
  const script = document.createElement('script');
  script.src = `https://maps.googleapis.com/maps/api/js?region=za&key=${process.env.REACT_APP_GOOGLE_SERVICES_API_KEY}&v=3.35&libraries=places`;
  script.async = true;
  document.head.appendChild(script);
};

// Create browser history with basename from environment
const history = createBrowserHistory({
  basename: process.env.REACT_APP_BASENAME || '/',
});

// Configure store
const store = configureStore(history);

// Load Google Maps script if API key is provided
if (process.env.REACT_APP_GOOGLE_SERVICES_API_KEY) {
  loadGoogleMapsScript();
}

// Render application
ReactDOM.render(
  <React.Fragment>
    <GlobalStyles />
    <Main store={store} history={history} />
  </React.Fragment>,
  document.getElementById("root")
);

// Register service worker
registerServiceWorker();

// Initialize Google Tag Manager
initializeGTM()
