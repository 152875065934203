import { isEmpty } from "lodash";
import React, { FunctionComponent } from "react";

import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

import Button from "../../components/buttons/Button";
import styled, { mediaDown } from "../../utils/styledComponents";
import { ErrorIcon, SuccessTickIcon } from "../icons";
import { useTheme } from "@material-ui/core";

const Container = styled.div`
  && {
    min-width: 500px;
    height: auto;
    display: flex;
    padding: 20px;
    border-radius: 4px;
    margin-top: 44px;
    flex-direction: column;
    border-radius: 4px;
    background: transparent;
    align-items: center;
    align-content: center;
    ${mediaDown.sm`padding: 10px;`}
    ${mediaDown.sm`min-width: auto;`}
    width: 100%;
    box-sizing: border-box;
  }
`;

const StyledCompletedHeading = styled.h3`
  font-family: ${({ theme }) => theme.fonts.MS700};
  font-weight: ${({ theme }) => theme.fontWeights.MS700};
  font-size: 20px;
  margin-bottom: 0px !important;
`;

const TitleContainer = styled.div`
  ::-webkit-scrollbar {
    background: transparent; /* Chrome/Safari/Webkit */
    width: 0px;
  }
  max-height: 300px;
  overflow-y: scroll;
  margin-bottom: 15px;
  scrollbar-width: none;
  width: 100%;
}`;

const Title = styled.h3`
  font-family: ${({ theme }) => theme.fonts.MS700};
  font-weight: ${({ theme }) => theme.fontWeights.MS700};
  font-size: 20px;
  color: ${({ theme }) => theme.colors.grey3};
  line-height: 34px;
  text-align: center;
  word-wrap: break-word;
`;

const StyledButton = styled(Button)`
  && {
    margin: 0px 7.5px 15px 7.5px;
  }
`;

const Content = styled.div`
  text-align: center;
`;

export interface CircularProgressLoaderProps {
  buttonText: string;
  messageText: string;
  isLoading: boolean;
  onFinish: (hasErrors: boolean) => void;
  errorMessage: string;
  actionText?: string;
  successText?: string;
  failedText?: string;
  hideDoneButton?: boolean;
  errorCheck?: boolean;
  className?: string;
}

const CircularProgressLoader: FunctionComponent<CircularProgressLoaderProps> = (props) => {
  const {
    isLoading,
    messageText,
    buttonText,
    onFinish,
    errorMessage,
    actionText,
    successText,
    failedText,
    hideDoneButton,
    errorCheck,
    className,
    children,
    ...validProps
  } = props;
  const hasErrors = !isEmpty(errorMessage);
  const onDone = () => {
    onFinish(hasErrors);
  };

  const onErrorCheckDone = () => {
    onFinish(false);
  };

  const theme = useTheme();
  return (
    <Container className={className}>
      {isLoading ? (
        <Box
          position="sticky"
          display="inline-flex"
          marginLeft="auto"
          marginRight="auto"
        >
          <CircularProgress
            size={200}
            variant="indeterminate"
            thickness={3}
            {...validProps}
          />

          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="caption">
              {actionText || "Creating"}
            </Typography>
          </Box>
        </Box>
      ) : (
        <Content>
          {hasErrors ? <ErrorIcon /> : <SuccessTickIcon fill={theme.palette.primary.main} />}
          <StyledCompletedHeading>
            {hasErrors ? failedText || "Not Created" : successText || "Created"}
          </StyledCompletedHeading>
        </Content>
      )}
      <TitleContainer>
        <Title>{hasErrors ? errorMessage : messageText}</Title>
      </TitleContainer>
      {!isLoading && !hideDoneButton && !children ? (
        <StyledButton
          name="publish"
          type="submit"
          disabled={false}
          variant="contained"
          loading={isLoading}
          onClick={errorCheck ? onErrorCheckDone : onDone}
        >
          {hasErrors ? "Take me back" : buttonText}
        </StyledButton>
      ) : (
        children
      )}
    </Container>
  );
};

export default CircularProgressLoader;
