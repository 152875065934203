import MuiSwitch, {
  SwitchClassKey,
  SwitchProps,
} from "@material-ui/core/Switch";
import { styled as muiStyled } from "@material-ui/core/styles"

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

export interface Props extends SwitchProps {
  classes: Styles;
}

const Switch = muiStyled(MuiSwitch)(({ theme }: any) => ({
  "& .MuiSwitch-track": {
    backgroundColor: theme.palette.grey[300],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
}))

export default Switch;
