import React from "react";
import styled, { mediaDown } from "../../utils/styledComponents";
import * as styledTheme from "../../styles/theme";
import { useTheme } from "@material-ui/core";

const StyledAlert = styled.div<{ borderColor: string }>`
  background: transparent;
  border: ${({ borderColor }) => `1px solid ${borderColor}`};
  border-radius: 0px;
  padding: 15px;
  width: 100%;
  ${mediaDown.sm`padding: 10px 0px; box-shadow: none;`}
`;

const AlertText = styled.div<{ fontColor: string }>`
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.MS500};
  font-weight: ${({ theme }) => theme.fontWeights.MS500};
  font-size: 16px;
  color: ${({ fontColor }) => fontColor};
  ${mediaDown.sm`font-size: 13px; font-family: ${styledTheme.theme.fonts.MS700};`}
`;

interface OwnProps {
  text: string;
}

const AlertBannerWidget: React.FunctionComponent<OwnProps> = (props) => {
  const { text } = props;
  const theme = useTheme();
  const secondaryColor = theme.palette.secondary.main;
  return (
    <StyledAlert borderColor={secondaryColor}>
      <AlertText fontColor={secondaryColor}>{text}</AlertText>
    </StyledAlert>
  );
};

export default AlertBannerWidget;
