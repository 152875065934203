import { transparentize } from "polished";
import React from "react";

import { ButtonProps } from "@material-ui/core/Button";
import MuiCircularProgress, {
  CircularProgressProps,
} from "@material-ui/core/CircularProgress";

import styled from "../../utils/styledComponents";
import { TickIcon } from "../icons";
import Button from "./Button";
import { useTheme } from "@material-ui/core";

interface OwnProps {
  loading?: boolean;
  isActive?: boolean;
}

type AllProps = ButtonProps & OwnProps;

const Icon = styled(TickIcon)`
  font-size: 50px;
  margin-right: 10px;
  margin-top: 0px;
`;

const StyledButton = styled(Button)<{ primaryColor: string }>`
  && {
    border-color: #dadada;
    &:hover {
      background: ${transparentize("0.25", "#fff")}!important;
      color: ${({ primaryColor }) => primaryColor} !important;
    }
  }
  color: ${({ theme, primaryColor, isActive }) => {
    return isActive ? primaryColor : theme.colors.grey2;
  }} !important;
  border-color: ${({ theme, primaryColor, isActive }) => {
    return isActive ? primaryColor : theme.colors.grey2;
  }} !important;
`;

const StyledProgress = styled(
  MuiCircularProgress as React.FunctionComponent<CircularProgressProps>
)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
  color: ${({ theme }) => theme.colors.white};
`;

const Wrapper = styled.div`
  position: relative;
  display: inline;
`;

const UserTypeButton: React.FunctionComponent<AllProps> = ({
  loading,
  isActive,
  ...props
}) => {
  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;
  return (
    <Wrapper>
      <StyledButton
        primaryColor={primaryColor}
        isActive={isActive}
        primary={isActive}
        outline={isActive}
        {...props}
      >
        {isActive && <Icon nativeColor="#fff" />}
        {props.children}
      </StyledButton>
      {loading && <StyledProgress size={24} color="inherit" />}
    </Wrapper>
  );
};

export default UserTypeButton;
