import { Grid } from "@material-ui/core";
import { get, isEmpty } from "lodash";
import React, { useCallback } from "react";
import styled, { mediaDown } from "../../utils/styledComponents";
import { Button } from "../buttons";
import InlineTitleDescriptionWidget from "../widgets/InlineTitleDescriptionWidget";
import TitleDescriptionWidget from "../widgets/TitleDescriptionWidget";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { useSnackbar } from 'notistack';

const HeaderContainer = styled.div`
  width: 100%;
`;
const Cols = styled(Grid).attrs(() => ({
  container: true,
  direction: "column",
  item: true,
}))``;

const Greyline = styled.div`
  color: #f0f0f0;
  margin: 5px 10px;
  width: 100%;
  border-bottom: solid 1px;
`;

const PullRightCols = styled(Grid).attrs(() => ({
  container: true,
  direction: "column",
  item: true,
}))`
  && {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    ${mediaDown.sm`justify-content: flex-start;`};
  }
`;
const StyledHeaderGrid = styled(Grid)`
  && {
    padding: 0px;
  }
`;

const StyledButton = styled(Button)`
  margin-right: 10px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  height: 30px !important;
  font-size: 12px !important;
`;

const ExpandButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
`;

const Container = styled.div`
  position: relative; /* Ensure the button is positioned relative to this container */
  min-height: 50px; /* Set a minimum height to ensure the container has a size */
`;

interface OwnProps {
  title: string;
  icon?: string;
  description?: string;
  counter?: string;
  counterHeading?: string;
  styles?: any;
  hasGreyLine?: boolean;
  isAddNewAd?: boolean;
  addNewAd?: () => void;
  isFacebookImport?: boolean;
  isCampaignEndDateInPast?: boolean;
  onExpand?: () => void;
  expanded?: boolean;
  showCopyAdPreviewLink?: boolean;
}

const HeadingBlock: React.FunctionComponent<OwnProps> = (props) => {
  const {
    title,
    description,
    counterHeading,
    icon,
    isAddNewAd,
    addNewAd,
    isFacebookImport,
    isCampaignEndDateInPast,
    onExpand,
    expanded,
    showCopyAdPreviewLink,
  } = props;
  const leftBlockStyles = get(props, "styles.leftBlock", undefined);
  const rightBlockStyles = get(props, "styles.rightBlock", undefined);
  const counter = get(props, "counter", "");
  const hasGreyLine = get(props, "hasGreyLine", false);

  const showAddNewAd = !!isAddNewAd && !isFacebookImport && isCampaignEndDateInPast === false;
  const showTopRightButtons = showAddNewAd || !!showCopyAdPreviewLink

  const selectedAdPreviewLink = useSelector<ApplicationState, string>(({ social }) => get(social, "selectedAd.previewLink", ""));

  const { enqueueSnackbar } = useSnackbar();

  const handleCopyAdPreviewLink = useCallback(async () => {
    if (selectedAdPreviewLink) {
      try {
        await navigator.clipboard.writeText(selectedAdPreviewLink);
        enqueueSnackbar(`Copied: ${selectedAdPreviewLink}`, {
          persist: false,
          preventDuplicate: true,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        });
      }
      catch (err) {
        enqueueSnackbar(`Failed to copy: ${selectedAdPreviewLink}`, {
          persist: false,
          preventDuplicate: true,
          variant: "info",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        });
      }
    }
  }, [selectedAdPreviewLink, enqueueSnackbar]);

  return (
    <HeaderContainer {...props}>
      <StyledHeaderGrid container={true}>
        <Cols item={true} sm={!isEmpty(counter) || showTopRightButtons ? 6 : 12}>
          <Container>
            <TitleDescriptionWidget
              title={title}
              icon={icon}
              styles={leftBlockStyles}
              description={description}
            />
            {typeof expanded !== "undefined" && (
              <ExpandButton onClick={onExpand}>
                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ExpandButton>
            )}
          </Container>
        </Cols>
        {!isEmpty(counter) && (
          <PullRightCols item={true} sm={6}>
            <InlineTitleDescriptionWidget
              title={counter}
              description={counterHeading}
              titleColor={get(rightBlockStyles, "title.color", "primary")}
              forceWrap={true}
              styles={{
                title: {
                  fontSize: get(rightBlockStyles, "title.fontSize", 20),
                  fontWeight: get(rightBlockStyles, "title.fontWeight", 900),
                },
                padding: get(rightBlockStyles, "padding", "5px 0px 10px 10px"),
                mobilePadding: get(
                  rightBlockStyles,
                  "mobilePadding",
                  "10px 0px 0px 0px"
                ),
              }}
            />
          </PullRightCols>
        )}

        {showTopRightButtons && (
          <PullRightCols item={true} sm={6}>
            {!!showCopyAdPreviewLink && (
              <StyledButton
                variant="contained"
                color="inherit"
                disabled={!selectedAdPreviewLink}
                onClick={handleCopyAdPreviewLink}
                style={{ marginBottom: 10 }}
                title="Copy Preview Link"
              >
                Copy Preview Link &nbsp; <FileCopyIcon style={{ fontSize: 12 }} />
              </StyledButton>
            )}
            {showAddNewAd && (
              <StyledButton
                type="submit"
                disabled={isFacebookImport!}
                variant="contained"
                fullWidth={false}
                loading={false}
                onClick={addNewAd}
              >
                {"Add New "}
              </StyledButton>
            )}
          </PullRightCols>
        )}
        {expanded && hasGreyLine && <Greyline />}
      </StyledHeaderGrid>
    </HeaderContainer>
  );
};

export default HeadingBlock;
