import React from "react";
import styled from "../../utils/styledComponents";
import ActionDialog from "./ActionDialog";
import { Annotation, UserHeadset, Brain } from "../icons";
import { theme } from "../../styles/theme";
import CustomLink from "../ui/CustomLink";

const StyledActionDialog = styled(ActionDialog)`
  && {
    .MuiPaper-root {
      max-width: 480px;
    }
  }
`;

const DialogBody = styled.div`
  color: ${({ theme }) => theme.colors.grey4};
  display: flex;
  flex-direction: column;
  font-size: 18px;
  padding: 0 20px 50px;
  gap: 15px;
`;

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export interface ConfirmDialogProps {
  openDialog: boolean;
  onClose: () => void;
}

const HelpDialog: React.FunctionComponent<ConfirmDialogProps> = ({
  openDialog,
  onClose,
}) => {
  return (
    <StyledActionDialog
      open={openDialog}
      title="Help and Support"
      contentComponent={
        <DialogBody>
          <ItemWrapper>
            <Brain nativeColor={theme.colors.grey4} />
            <CustomLink
              styles={{ fontSize: "18px" }}
              url="https://help.flowliving.com"
              title="View our Knowledgebase"
            />
          </ItemWrapper>
          <ItemWrapper>
            <UserHeadset nativeColor={theme.colors.grey4} />
            <CustomLink
              styles={{ fontSize: "18px" }}
              url="mailto:support@flowliving.com"
              title="Contact Support"
            />
          </ItemWrapper>
          <ItemWrapper>
            <Annotation nativeColor={theme.colors.grey4} />
            <span>Speak directly to your account manager</span>
          </ItemWrapper>
        </DialogBody>
      }
      onBackdropClick={onClose}
      onClose={onClose}
    />
  );
};

export default HelpDialog;
