import React from "react";
import Switch from "../ui/Switch";
import styled from "../../utils/styledComponents";

const StyledHeading = styled.label`
  color: ${({ theme }) => theme.colors.grey3};
`;

const ToggleContainer = styled.div`
  && {
    display: flex;
    flex-direction: column;
    padding-left: 9px;
    margin-left: 15px;
    margin-right: 15px;
  }
`;

const ToggleWrapper = styled.div`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

interface OwnProps {
  label: string;
  name: string;
  disabled?: boolean;
  checked: boolean;
  onChange: (value: boolean) => void;
}

const ToggleWithLabel: React.FunctionComponent<OwnProps> = (props) => {
  const { label, onChange, checked, disabled, name } = props;
  return (
    <ToggleContainer>
      <ToggleWrapper>
        <Switch
          name={name}
          disabled={disabled || false}
          checked={checked}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const selectedValue = event.target.checked;
            onChange(selectedValue);
          }}
        />
        <StyledHeading>{label}</StyledHeading>
      </ToggleWrapper>
    </ToggleContainer>
  );
};

export default ToggleWithLabel;
